<template>
  <section id="section">
    <div id="homepage">
      <HeroBanner
        id="hero"
        path="/images/Campaign/2024/Winter/still/"
        xl="XL_Homepage_x{ratio}.jpg"
        l="L_Homepage_x{ratio}.jpg"
        m="M_Homepage_x{ratio}.jpg"
        s="S_Homepage_x{ratio}.jpg"
        xs="XS_Homepage_x{ratio}.jpg"
        :alt="$t('to-new-beginnings')"
        webp
        :header="$t('to-new-beginnings')"
        :description="$t('to-new-beginning-description')"
        :links="[
          { text: $t('shop-engagement'), to: localePath('/engagement-rings') },
          { text: $t('shop-diamond-jewellery'), to: localePath('/diamond-jewellery') },
        ]"
        class="responsive-hero text-white"
        header-class="heading-1 uppercase"
        description-class="editorial-2 mt-1"
        link-wrapper-class="hero__link-container"
        link-class="body-12-aa-cap hero__link"
      />
      <ShowcasesSection />
      <BestSellerSlider />
      <DiamondsSection />
      <Banner77
        class="middle p-bottom text-white"
        path="/images/Homepage/WideBanner/"
        webp
        alt="Bespoke Jewellery"
        xs="XS_Wide_x{ratio}.jpg"
        s="S_Wide_x{ratio}.jpg"
        m="M_Wide_x{ratio}.jpg"
        l="L_Wide_x{ratio}.jpg"
        xl="XL_Wide_x{ratio}.jpg"
        contentClass="_py-2"
      >
        <div class="md:container">
          <h2 class="hero-banner--title heading-3 mb-1.5">
            {{ $t('bespoke-jewellery') }}
          </h2>
          <p class="hero-banner--content body-14-aa mb-3 sm:w-1/2 lg:w-1/3">
            {{ $t('bespoke-jewellery-description-new') }}
          </p>
          <NuxtLinkLocale href="/bespoke-jewellery" class="hero-banner--link btn-link text-white uppercase">
            {{ $t('discover-bespoke') }}
          </NuxtLinkLocale>
        </div>
      </Banner77>
      <ShowroomSection />
      <ReviewSlider />
      <CategorySlider />
    </div>
  </section>
</template>

<script setup lang="ts">
import Banner77 from '@/components/ui/Banner77.vue'
import ShowcasesSection from '~/components/home/ShowcasesSection.vue'
import DiamondsSection from '@/components/home/DiamondsSection.vue'
import BestSellerSlider from '@/components/home/BestSellerSlider.vue'
import ShowroomSection from '@/components/home/ShowroomSection.vue'
import ReviewSlider from '~/components/home/ReviewSlider.vue'
import CategorySlider from '@/components/home/CategorySlider.vue'
import HeroBanner from '~/components/ui/HeroBanner.vue'

defineI18nRoute({
  paths: { page_id: '/22' },
})

import { loadBF2024 } from '~/helpers/home'

const localePath = useLocalePath()

onMounted(() => {
  loadBF2024()
})

// TODO: convert other Banner77 to HeroBanner
</script>

<style lang="scss">
/* @import '@/assets/css/home.css'; */

/* a:hover {
  opacity: 0.8;
  text-decoration: underline;
} */

#hero #HeroVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero__description {
  max-width: 555px;
  @apply text-center text-sm font-domaine italic mb-0 mt-2.5;
}

.hero__link-container {
  @apply flex flex-col sm:flex-row justify-center items-center gap-3 lg:gap-5 mt-4 w-full;
}

.hero__link {
  @media (max-width: 479.8px) {
    @apply border border-white py-3 w-full;
  }

  @media (min-width: 480px) {
    @apply underline underline-offset-4;
  }
}

.hero-banner.responsive-hero {
  padding-top: calc(500 / 375 * 100%);

  @media (min-width: 481px) {
    padding-top: calc(500 / 768 * 100%);
  }

  @media (min-width: 769px) {
    padding-top: calc(500 / 1024 * 100%);
  }

  @media (min-width: 1025px) {
    padding-top: calc(500 / 1440 * 100%);
  }

  @media (min-width: 1441px) {
    padding-top: calc(500 / 1920 * 100%);
  }

  .banner-picture {
    @media (min-width: 1004px) and (max-width: 1024px),
      (min-width: 1420px) and (max-width: 1440px),
      (min-width: 1900px) and (max-width: 1920px) {
      object-fit: none;
    }
  }
}
</style>
