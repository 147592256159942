import { CDN_PATH } from '~/constants'

const videoSizes: Record<string, number> = {
  XL: 1440,
  L: 1025,
  M: 769,
  S: 481,
  XS: 0,
}

const getVideoContent = (videoFile: string) => `
    <video id="HeroVideo" loop autoplay playsinline muted>
      <source src="${videoFile}" type="video/mp4" />
    </video>
  `

const getVideoFilename = (width: number, folderName: string, year: number): string => {
  const retinaSupport = width >= videoSizes.XL ? 'x2' : 'x1'
  const sizeKey = Object.keys(videoSizes).find((key) => width > videoSizes[key]) || videoSizes.M
  return `${CDN_PATH}/images/Campaign/${year}/${folderName}/${sizeKey}_Homepage_${retinaSupport}.mp4`
}

export function appendVideo(folderName: string, elementClass: string, year: number) {
  const videoFile = getVideoFilename(window.innerWidth, folderName, year)
  // not sure, we need elementClass in selector, #homepage #hero should be enough
  // const videoArea = document.querySelector(`#homepage #hero .${elementClass} picture`) as HTMLElement
  const videoArea = document.querySelector(`#homepage #hero picture`) as HTMLElement

  // avoid adding video twice
  if (!videoArea || videoArea.nextElementSibling?.tagName === 'VIDEO') {
    return
  }

  videoArea.insertAdjacentHTML('afterend', getVideoContent(videoFile))

  const videoElement = videoArea.nextElementSibling as HTMLVideoElement
  const deviceIsiOS = /iPad|iPhone|iPod/.test(navigator.platform)

  if (deviceIsiOS) {
    videoElement.controls = false
    videoElement.addEventListener('canplaythrough', () => videoElement.play())
  } else {
    if (videoElement.readyState === 4) {
      videoArea.style.display = 'none'
    } else {
      videoElement.addEventListener('loadeddata', () => {
        videoArea.style.display = 'none'
      })
    }
  }
}

export function loadVideo(folderName: string, elementClass: string, year: number) {
  const append = () => {
    window.removeEventListener('scroll', append)
    document.getElementById('hero')?.removeEventListener('mouseover', append)
    appendVideo(folderName, elementClass, year)
  }

  window.addEventListener('scroll', append)
  document.getElementById('hero')?.addEventListener('mouseover', append)
}

export function loadLateSummerVideo() {
  function appendLateSummerVideo() {
    window.removeEventListener('scroll', appendLateSummerVideo)
    appendVideo('LateSummer', 'late-summer', 2023)
  }

  window.addEventListener('scroll', appendLateSummerVideo)
}

export function loadValentines2024() {
  loadVideo('Winter', 'valentines-2024', 2024)
}

export function loadBoxingDayAndNYEVideo() {
  loadVideo('BoxingDayAndNYE', 'new-year-sale', 2023)
}

export function loadChristmasSaleVideo() {
  loadVideo('Christmas/Homepage', 'christmas-sale', 2023)
}

export function loadBF2024() {
  loadVideo('Winter', 'valentines-2024', 2024)
}
